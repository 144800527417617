var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "new-note",
        title: "New Note",
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "md",
      },
      on: { hide: _vm.resetModal },
    },
    [
      _c(
        "div",
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [
              _c("b", [
                _vm._v(
                  "You must provide at least a title or description to create a new note."
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-3" },
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-form-group",
                { attrs: { id: "note-type", label: "Type:" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.types },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function () {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null, disabled: "" } },
                              [_vm._v("-- Please select an option --")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.note.note_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.note, "note_type", $$v)
                      },
                      expression: "note.note_type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { id: "note-title", label: "Title:" } },
                [
                  _c("b-form-input", {
                    model: {
                      value: _vm.note.note_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.note, "note_title", $$v)
                      },
                      expression: "note.note_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { id: "note-body", label: "Body:" } },
                [
                  _c("b-form-textarea", {
                    model: {
                      value: _vm.note.note_body,
                      callback: function ($$v) {
                        _vm.$set(_vm.note, "note_body", $$v)
                      },
                      expression: "note.note_body",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "note-submit-btn",
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Create Note")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }