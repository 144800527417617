import { render, staticRenderFns } from "./NoteCard.vue?vue&type=template&id=72d73fe0&scoped=true&"
import script from "./NoteCard.vue?vue&type=script&lang=js&"
export * from "./NoteCard.vue?vue&type=script&lang=js&"
import style0 from "./NoteCard.vue?vue&type=style&index=0&id=72d73fe0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d73fe0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ethan/Documents/Github/avosina/web/avosina-claims/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72d73fe0')) {
      api.createRecord('72d73fe0', component.options)
    } else {
      api.reload('72d73fe0', component.options)
    }
    module.hot.accept("./NoteCard.vue?vue&type=template&id=72d73fe0&scoped=true&", function () {
      api.rerender('72d73fe0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/notes/NoteCard.vue"
export default component.exports