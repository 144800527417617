var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0 pt-3" },
    [
      _vm.mode === "list"
        ? _c(
            "b-row",
            {
              staticClass: "px-5",
              attrs: { "align-v": "center", "align-h": "between" },
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { sm: "12", md: "7", lg: "6" },
                },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "note-search-input pl-1",
                        attrs: {
                          type: "search",
                          placeholder: "Try typing a note's title",
                        },
                        on: {
                          keyup: _vm.debounceNotes,
                          search: _vm.debounceNotes,
                        },
                        model: {
                          value: _vm.search.terms,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "terms", $$v)
                          },
                          expression: "search.terms",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("span", { staticClass: "divider" }),
                          _c("span", { staticClass: "divider" }),
                          _c(
                            "b-dropdown",
                            {
                              ref: "dropdown",
                              staticClass: "dropdown filter-dropdown",
                              attrs: { boundary: "window" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("b-icon-funnel", {
                                          staticClass: "mr-2",
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.filterInputLabel) + " "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2411036029
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass:
                                    "d-flex flex-column align-items-start",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "20px",
                                        "font-weight": "300",
                                      },
                                    },
                                    [_vm._v("Filters")]
                                  ),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Note Type"),
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      "hide-selected": true,
                                      options: _vm.types,
                                      "track-by": "type",
                                      label: "label",
                                      multiple: true,
                                      "show-labels": false,
                                      placeholder: "-- Select an Option --",
                                    },
                                    model: {
                                      value: _vm.search.types,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "types", $$v)
                                      },
                                      expression: "search.types",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Worker"),
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      "hide-selected": true,
                                      options: _vm.workers,
                                      "track-by": "worker_id",
                                      label: "name",
                                      multiple: true,
                                      "show-labels": false,
                                      placeholder: "-- Select an Option --",
                                    },
                                    model: {
                                      value: _vm.search.workers,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "workers", $$v)
                                      },
                                      expression: "search.workers",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Created After"),
                                  ]),
                                  _c("b-form-input", {
                                    ref: "date1",
                                    staticClass: "date-input",
                                    attrs: {
                                      type: "date",
                                      "value-as-date": "",
                                      locale: "en",
                                    },
                                    model: {
                                      value: _vm.search.start_date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "start_date", $$v)
                                      },
                                      expression: "search.start_date",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Created Before"),
                                  ]),
                                  _c("b-form-input", {
                                    ref: "date2",
                                    staticClass: "date-input",
                                    attrs: {
                                      type: "date",
                                      "value-as-date": "",
                                      locale: "en",
                                    },
                                    model: {
                                      value: _vm.search.end_date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "end_date", $$v)
                                      },
                                      expression: "search.end_date",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end mt-4",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-basic-btn mr-2",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.onClearFilters },
                                        },
                                        [_vm._v("Clear Filters")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-primary-btn",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.getNotes },
                                        },
                                        [_vm._v("Apply")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-right",
                  attrs: { sm: "12", md: "5", lg: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.new-note",
                          modifiers: { "new-note": true },
                        },
                      ],
                      staticClass: "avo-primary-btn mt-4 mt-md-0",
                      attrs: { variant: "outline-primary" },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add Note "),
                    ],
                    1
                  ),
                  _c("note-add", {
                    attrs: { claimId: _vm.claimId, preauthId: _vm.preauthId },
                    on: { noteAdded: _vm.onNotesUpdate },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm.mode === "inline" && _vm.showInlineHeader
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between px-4" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "label-text",
                        staticStyle: {
                          "text-decoration": "underline var(--blue) 3px",
                          "text-underline-offset": "1.2rem",
                        },
                      },
                      [_vm._v("Notes")]
                    ),
                    !_vm.hideViewAll
                      ? _c("div", { staticClass: "avo-text-light" }, [
                          _vm._v("View all notes"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c("hr", { staticClass: "w-100" }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-overlay",
        {
          staticStyle: { "min-height": "100px" },
          attrs: { show: _vm.notesLoading, variant: "white" },
        },
        [
          _vm.mode === "list"
            ? [
                _c(
                  "div",
                  { staticClass: "p-5" },
                  [
                    _c(
                      "b-row",
                      _vm._l(_vm.notes, function (note) {
                        return _c(
                          "b-col",
                          {
                            key: note.note_id,
                            staticClass: "col-12 col-md-6 col-lg-4",
                          },
                          [
                            _c("note-card", {
                              key: note.note_id,
                              attrs: { note: note, mode: "list" },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.mode === "inline" && _vm.showInlineHeader
            ? [
                _c(
                  "div",
                  { staticClass: "pt-4 pr-4 pl-4" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          _vm._l(_vm.notes, function (note) {
                            return _c("note-card", {
                              key: note.note_id,
                              attrs: { note: note, mode: "inline" },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.mode === "inline" && !_vm.showInlineHeader
            ? [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          _vm._l(_vm.notes, function (note) {
                            return _c("note-card", {
                              key: note.note_id,
                              attrs: { note: note, mode: "inline" },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.notesLoading
            ? _c("div", { staticClass: "no-notes pt-4" }, [
                _vm._v(" Fetching notes "),
              ])
            : _vm._e(),
          _vm.notes.length == 0 && !_vm.notesLoading
            ? _c("div", { staticClass: "no-notes pt-4" }, [
                _vm._v(" No notes found "),
              ])
            : _vm._e(),
          _vm.showPagination && _vm.notes.length > 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPageVar,
                          align: "center",
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }