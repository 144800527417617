var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      class: [
        { "card-height": _vm.mode === "list", shadow: _vm.mode === "list" },
      ],
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _vm.mode === "list"
                    ? [
                        _c("b-avatar", {
                          staticClass: "mr-2",
                          attrs: { text: _vm.note.initials },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-column align-items-start",
                          },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.note.name))]),
                            _c("div", { staticClass: "created-text" }, [
                              _vm._v(
                                "Created on " +
                                  _vm._s(
                                    new Date(_vm.note.created).toLocaleString(
                                      "en-US",
                                      {
                                        month: "numeric",
                                        day: "numeric",
                                        year: "numeric",
                                      }
                                    )
                                  )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : _vm.mode === "inline"
                    ? [
                        _c("b-avatar", {
                          staticClass: "mr-3",
                          attrs: {
                            icon: "file-earmark",
                            variant: "primary",
                            size: "2rem",
                          },
                        }),
                        _c("strong", [_vm._v(_vm._s(_vm.note.title))]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex p-1" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column justify-content-start" },
                  [
                    _c("div", { staticClass: "mb-1" }, [
                      _c("strong", [_vm._v("Tags")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap" },
                      [_c("b-badge", [_vm._v("#" + _vm._s(_vm.note.label))])],
                      1
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "text-right" }, [
                _vm.mode === "list"
                  ? _c("div", { staticClass: "modified-text" }, [
                      _vm._v(
                        " Last modified on " +
                          _vm._s(
                            new Date(_vm.note.updated).toLocaleString("en-US", {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                            })
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.mode === "inline"
                  ? _c("div", { staticClass: "modified-text" }, [
                      _vm._v(
                        " Last modified by " +
                          _vm._s(_vm.note.name) +
                          " on " +
                          _vm._s(
                            new Date(_vm.note.updated).toLocaleString("en-US", {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                            })
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-card-body",
        { attrs: { title: _vm.mode === "list" ? _vm.note.title : "" } },
        [_c("b-card-text", [_vm._v(_vm._s(_vm.note.body))])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }